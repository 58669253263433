import {
  Heading,
  Text,
  Badge,
  Box,
  chakra,
  Flex,
  Tooltip,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

type Event = {
  name: string;
  desc: string | undefined;
  link: string;
  date: string | undefined;
  month: string;
  spotMessage: string | undefined;
  isBookable: boolean;
};

type Clinic = {
  name: string;
  link: string;
};

const backhandClinic: Clinic = {
  name: "Backhand Clinic",
  link: "https://transactions.sendowl.com/products/79210796/836BA994/view",
};

const backhandSliceClinic: Clinic = {
  name: "Backhand Slice Clinic",
  link: "https://transactions.sendowl.com/products/79215704/74768652/view",
};

const flatServeClinic: Clinic = {
  name: "Flat Serve Clinic",
  link: "https://transactions.sendowl.com/products/79215716/96F0B3E7/view",
};

const spinServeClinic: Clinic = {
  name: "Spin Serve Clinic",
  link: "https://transactions.sendowl.com/products/79215718/366A9176/view",
};

const volleyServeClinic: Clinic = {
  name: "Volley Clinic",
  link: "https://transactions.sendowl.com/products/79215714/EBCD00EE/view",
};

const privateLessons: Clinic = {
  name: "Private Tennis Lesson - 1 hour",
  link: "https://transactions.sendowl.com/products/79229583/B383F5F1/view",
};

const events: Event[] = [
  {
    date: undefined,
    name: privateLessons.name,
    desc: "Personalized Coaching to Improve Your Skills and Technique",
    link: privateLessons.link,
    month: "",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "07.09",
    name: flatServeClinic.name,
    desc: undefined,
    link: flatServeClinic.link,
    month: "September 2024",
    spotMessage: undefined,
    isBookable: false,
  },
  {
    date: "14.09",
    name: spinServeClinic.name,
    desc: undefined,
    link: spinServeClinic.link,
    month: "September 2024",
    spotMessage: undefined,
    isBookable: false,
  },
  {
    date: "21.09",
    name: backhandClinic.name,
    desc: undefined,
    link: backhandClinic.link,
    month: "September 2024",
    spotMessage: undefined,
    isBookable: false,
  },
  {
    date: "28.09",
    name: volleyServeClinic.name,
    desc: undefined,
    link: volleyServeClinic.link,
    month: "September 2024",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "05.10",
    name: backhandSliceClinic.name,
    desc: undefined,
    link: backhandSliceClinic.link,
    month: "October 2024",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "12.10",
    name: flatServeClinic.name,
    desc: undefined,
    link: flatServeClinic.link,
    month: "October 2024",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "19.10",
    name: spinServeClinic.name,
    desc: undefined,
    link: spinServeClinic.link,
    month: "October 2024",
    spotMessage: undefined,
    isBookable: false,
  },
  {
    date: "26.10",
    name: backhandClinic.name,
    desc: undefined,
    link: backhandClinic.link,
    month: "October 2024",
    spotMessage: undefined,
    isBookable: false,
  },
  {
    date: "02.11",
    name: volleyServeClinic.name,
    desc: undefined,
    link: volleyServeClinic.link,
    month: "November 2024",
    spotMessage: undefined,
    isBookable: false,
  },
  {
    date: "09.11",
    name: backhandSliceClinic.name,
    desc: undefined,
    link: backhandSliceClinic.link,
    month: "November 2024",
    spotMessage: undefined,
    isBookable: false,
  },
  {
    date: "16.11",
    name: flatServeClinic.name,
    desc: undefined,
    link: flatServeClinic.link,
    month: "November 2024",
    spotMessage: undefined,
    isBookable: false,
  },
  {
    date: "23.11",
    name: spinServeClinic.name,
    desc: undefined,
    link: spinServeClinic.link,
    month: "November 2024",
    spotMessage: "full",
    isBookable: false,
  },
  /*{
    date: "30.11",
    name: backhandClinic.name,
    desc: undefined,
    link: backhandClinic.link,
    month: "November 2024",
    spotMessage: undefined,
    isBookable: true,
  },*/
  {
    date: "07.12",
    name: volleyServeClinic.name,
    desc: undefined,
    link: volleyServeClinic.link,
    month: "December 2024",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "14.12",
    name: backhandSliceClinic.name,
    desc: undefined,
    link: backhandSliceClinic.link,
    month: "December 2024",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "21.12",
    name: flatServeClinic.name,
    desc: undefined,
    link: flatServeClinic.link,
    month: "December 2024",
    spotMessage: undefined,
    isBookable: true,
  },
];

type ClinicItemProps = {
  event: Event;
};

// Define the type for groupedEvents
type GroupedEvents = {
  [key: string]: Event[];
};

function ClinicItem({ event }: ClinicItemProps) {
  return (
    <Box
      bg={event.isBookable ? "white" : "#e5e5e5"}
      borderWidth="1px"
      rounded="lg"
      shadow="lg"
      position="relative"
    >
      <Box p={{ base: "4" }} minW={{ base: "280", md: "340" }}>
        <Box display="flex" alignItems="baseline">
          {event.spotMessage && (
            <Badge
              rounded="full"
              px="4"
              py={2}
              fontSize="0.8em"
              colorScheme="red"
            >
              {event.spotMessage}
            </Badge>
          )}
        </Box>
        <Flex mt="1" justifyContent="space-between" alignContent="center">
          <Box
            fontSize="2xl"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {event.name}
          </Box>

          <Tooltip
            label="Add to cart"
            bg="white"
            placement={"top"}
            color={"gray.800"}
            fontSize={"1.2em"}
          >
            <chakra.a href={"#"} display={"flex"}></chakra.a>
          </Tooltip>
        </Flex>
        {event.desc && (
          <Box fontSize="lg" fontWeight="normal" isTruncated>
            {event.desc}
          </Box>
        )}
        <Flex justifyContent="space-between" alignContent="center">
          <Box fontSize="xl" color={useColorModeValue("gray.800", "white")}>
            <Box as="span" color={"gray.600"} fontSize="lg">
              📅 &nbsp;&nbsp;&nbsp;
            </Box>
            {event.date || "On mutual agreement"}
          </Box>
        </Flex>
        {!event.isBookable && <Text fontSize={"lg"}>No spot left</Text>}
        {event.isBookable && (
          <Link href={event.link} isExternal color={"blue"} fontSize={"2xl"}>
            Book now <ExternalLinkIcon mx="2px" />
          </Link>
        )}
      </Box>
    </Box>
  );
}

export default function Courses() {
  // Get the current date
  const today = new Date();
  const currentYear = today.getFullYear();

  // Filter events
  const upcomingEvents = events.map((event) => {
    if (!event.date) return event; // Keep events without a date

    // Parse the event date
    const [day, month] = event.date.split(".").map(Number);
    const eventDate = new Date(currentYear, month - 1, day); // Months are 0-based

    // Check if the event date is in the future
    if (event.isBookable && eventDate < today) {
      const newEvent: Event = {
        ...event,
        isBookable: false,
      };
      return newEvent;
    }
    return event;
  });

  // Group events by month
  const groupedEvents: GroupedEvents = upcomingEvents.reduce(
    (acc: GroupedEvents, event: Event) => {
      const { month } = event;
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(event);
      return acc;
    },
    {}
  );

  const colors = [
    "purple.50",
    "orange.50",
    "teal.50",
    "pink.50",
    "blue.50",
    "yellow.50",
    "cyan.50",
  ];

  return (
    <section id="clinics" className="section">
      <div className="container">
        <h1 className="title">Tennis Clinics/Lessons</h1>
        <Text fontSize={"xl"}>
          The Autumn season kick starts with clinics starting on{" "}
          <strong>Saturday September 7, 2024</strong>. Key information about
          clinics:
        </Text>
        <ul>
          <li>
            <Text fontSize={"lg"}>
              · &nbsp;&nbsp; The clinics are adapted to the individual level and
              individual need of the participants.
            </Text>
          </li>
          <li>
            <Text fontSize={"lg"}>
              · &nbsp;&nbsp; Note: All clinics are organized in
              <strong>Tali Tenniskeskus, Helsinki</strong>,{" "}
              <strong>Court #6</strong>, <strong>03:00-04:00 PM</strong>
            </Text>
          </li>
        </ul>
        <Box bg={"blue.50"} p={4} rounded={8} fontSize={"xl"} mt={4}>
          👉 &nbsp;&nbsp; For any other queries or private lessons, feel free to
          reach out on via email at <strong>alainbrouillaud@mac.com </strong>or
          give a call/whatsapp <strong>040 733 9282</strong>
        </Box>
        <Box bg={"red.50"} p={4} rounded={8} fontSize={"xl"} mt={4}>
          👉 &nbsp;&nbsp; For <strong>MobilePay</strong> payment, reach out to
          Alain by call/whatsapp <strong>040 733 9282</strong>
        </Box>
        {Object.keys(groupedEvents).map((month, index) => (
          <Box
            key={month}
            bg={colors[index]}
            p={{ base: "2", md: "4" }}
            rounded={8}
            my={{ base: "4", md: "4" }}
          >
            {month && (
              <Heading as="h4" fontSize={"2xl"} mt={4} mx={4}>
                {month}
              </Heading>
            )}
            <Flex
              gap="4"
              alignItems={"flex-start"}
              wrap={"wrap"}
              p={4}
              direction={"row"}
              rounded={12}
            >
              {groupedEvents[month].map((event, index) => (
                <ClinicItem key={index} event={event} />
              ))}
            </Flex>
          </Box>
        ))}
      </div>
    </section>
  );
}
